import {
    Directive,
    Input,
    OnChanges,
    SimpleChanges,
    ElementRef,
    Output,
    EventEmitter,
} from "@angular/core";

@Directive({
    selector: "[trackRender]",
})
export class TrackRenderDirective implements OnChanges {
    @Input() trigger: boolean;
    @Output() loadingChange = new EventEmitter<boolean>();

    private loading: boolean;

    constructor(private elementRef: ElementRef) {}

    ngOnChanges(changes: SimpleChanges) {
        if (changes.trigger === undefined) {
            return;
        }
        const { firstChange, previousValue, currentValue } = changes.trigger;

        if (!firstChange && previousValue !== currentValue) {
            this.toggleLoadingState();
        }
    }

    private toggleLoadingState() {
        this.loading = true;
        this.loadingChange.emit(this.loading);

        const observer = new MutationObserver(() => {
            this.loading = false;
            this.loadingChange.emit(this.loading);
            observer.disconnect();
        });

        observer.observe(this.elementRef.nativeElement, {
            childList: true,
            subtree: true,
        });
    }
}
