import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { TrackRenderDirective } from "./track-render.directive";

@NgModule({
    declarations: [TrackRenderDirective],
    imports: [CommonModule],
    exports: [TrackRenderDirective],
})
export class TrackRenderModule {}
