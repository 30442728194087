import { Injectable } from "@angular/core";

@Injectable({
    providedIn: "root",
})
export class PlanningBoardRendererService {
    tripsLoaderState = false;
    bussesLoaderState = false;
    driversLoaderState = false;

    driversLoaderWasInitiated = false;
    bussesLoaderWasInitiated = false;
    tripsLoaderWasInitiated = false;
}
